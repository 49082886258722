import React from 'react';
import { Box, Button, Switch, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CalendarHeader = ({ dateRange, onPrev, onNext, availability, onToggleAvailability }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={onPrev}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{dateRange}</Typography>
        <IconButton onClick={onNext}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">Demo availability is {availability ? "ON" : "OFF"}</Typography>
        <Switch checked={availability} onChange={onToggleAvailability} />
      </Box>
    </Box>
  );
};

export default CalendarHeader;
