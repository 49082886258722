import { Box, Typography, Paper } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const StarTeacherSection = ({ achievement }) => {
  return (
    <Paper elevation={2} sx={{ p: 2, mb: 3, background: '#b92323', borderRadius: '10px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" color="common.white">
            <StarIcon sx={{ color: '#96fafd' }} /> Become a Star Teacher
          </Typography>
          <Typography variant="body2" color="common.white">
            Teach with passion, gain with pride
          </Typography>
        </Box>
        <Typography variant="body2" color="common.white">
22/09/2009        </Typography>
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="body2"  color="common.white">
            Get <b>10% additional</b> payout by achieving STAR status on all criteria.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" sx={{ background: '#ffe082', borderRadius: '5px', p: 1 }}>
            100
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default StarTeacherSection;
