import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import MyTabs from '../Components/MyTabs';
import SearchBar from '../Components/SearchBar';
import GroupsList from '../Components/GroupList';

const groupsData = [
    {
      id: '228',
      name: 'Group 3',
      gradeRange: '1st to 12th Grade',
      studentCount: 0,
      country: 'India',
      lastClassDate: '2023-08-16',
      lessonsCompleted: 63,
      totalLessons: 63,
      status: 'active',
    },
    {
      id: '234',
      name: 'Group 2',
      gradeRange: '1st to 12th Grade',
      studentCount: 1,
      country: 'India',
      lastClassDate: '2023-10-07',
      lessonsCompleted: 131,
      totalLessons: 150,
      status: 'past',
    },
    // Add more groups with different statuses and details
  ];
  

const MyGroup = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const filteredGroups = groupsData.filter((group) =>
    group.name.toLowerCase().includes(searchQuery)
  );

  const activeGroups = filteredGroups.filter((group) => group.status === 'active');
  const pastGroups = filteredGroups.filter((group) => group.status === 'past');

  const tabs = [
    { label: 'Active Groups', groups: activeGroups },
    { label: 'Past Groups', groups: pastGroups },
  ];

  return (
    <Container>
      <Box mt={3}>
        <Typography variant="h4">My Groups</Typography>
      </Box>
      <MyTabs tabs={tabs} onTabChange={handleTabChange} />
      <SearchBar onSearch={handleSearch} />
      <GroupsList groups={tabs[tabIndex].groups} />
    </Container>
  );
};

export default MyGroup;
