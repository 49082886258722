import { Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';

const MyTabs = ({ tabs, onTabChange }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} centered sx={{ mb: 2 }}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
    </Box>
  );
};

export default MyTabs;
