import { Box, Card, CardContent, Typography, Button, Chip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PiggyBankIcon from '@mui/icons-material/Savings'; // Placeholder for piggy bank icon

const GroupCard = ({ group }) => {
  return (
    <Card sx={{ maxWidth: 300, m: 1 }}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={2}>
          <PiggyBankIcon sx={{ fontSize: 60, color: 'primary.main' }} /> {/* Placeholder icon */}
        </Box>
        <Chip label={group.id} color="secondary" sx={{ mb: 1 }} />
        <Typography variant="h6" align="center">{group.name}</Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {group.gradeRange} | {group.studentCount} students | {group.country}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
          Last class date: {group.lastClassDate}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {group.lessonsCompleted} / {group.totalLessons} Lessons Completed
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="outlined" startIcon={<SchoolIcon />}>
            View Learning Path
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GroupCard;
