import React, { useState, useEffect } from 'react';
import { Container, Box, Button, TextField, Typography, Tabs, Tab, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TimeSlotSelector from '../Components/TimeSlotSelector';
import dayjs from 'dayjs';
import baseUrl from '../config';
import CalendarHeader from '../Components/CalnederHeader';
import { generateHourlySlots } from '../utils/timeUtils';

const AvailabilityCard = () => {
  const [tabIndex, setTabIndex] = useState(0); // Manage tab index state
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day').toDate());
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [availability, setAvailability] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const today = dayjs().startOf('day');
  const nextWeekStart = today.add(1, 'day');
  const nextWeekEnd = nextWeekStart.add(6, 'days');

  // Fetch availability data
  const fetchAvailability = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}api/teachers/get-availability`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const formattedAvailability = data.reduce((acc, { date, timeSlots }) => {
          const formattedDate = dayjs(date).format('YYYY-MM-DD');
          acc[formattedDate] = timeSlots;
          return acc;
        }, {});
        setAvailability(formattedAvailability);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to fetch availability');
      }
    } catch (error) {
      console.error('Error fetching availability:', error);
      setError('An error occurred while fetching availability');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.toDate() : today.toDate());
  };

  const handleSlotSelection = (slotValue) => {
    setSelectedSlots((prevSlots) =>
      prevSlots.includes(slotValue)
        ? prevSlots.filter((slot) => slot !== slotValue)
        : [...prevSlots, slotValue]
    );
  };

  const handleSaveAvailability = async () => {
    if (selectedDate && selectedSlots.length > 0) {
      const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
      setLoading(true);
      setError('');
      setSuccess('');

      try {
        const response = await fetch(`${baseUrl}api/teachers/set-availability`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ date: formattedDate, timeSlots: selectedSlots }),
        });

        if (response.ok) {
          setSuccess('Availability updated successfully');
          setSelectedSlots([]);
          await fetchAvailability();
        } else {
          const data = await response.json();
          setError(data.message || 'An error occurred while updating availability');
        }
      } catch (error) {
        console.error('Error updating availability:', error);
        setError('An error occurred while updating availability');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please select a date and at least one time slot');
    }
  };

  // Get the availability slots for the selected date
  const formattedSelectedDate = dayjs(selectedDate).format('YYYY-MM-DD');
  const slotsAvailable = availability[formattedSelectedDate] || [];
  const totalSlots = 20; // Example value
  const allSlots = generateHourlySlots().map(slot => slot.value);
  const unavailableSlots = allSlots.filter(slot => !slotsAvailable.includes(slot));

  return (
   <Box
      display="flex"
      flexDirection="column"
      padding={3}
      sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
    >        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          variant="fullWidth"
          sx={{ mb: 3 }}
        >
          {/* <Tab label="Date Picker Timeslot" /> */}
          <Tab label="Regular Classes" />
          <Tab label="Demo Booked Classes" />
          <Tab label="All Classes" />
        </Tabs>
        
        {/* Tab Panel for Date Picker Timeslot */}
        {/* {tabIndex === 0 && (
          <Box>
            <CalendarHeader
              dateRange={`${today.format('DD MMM')} - ${nextWeekEnd.format('DD MMM')}`}
              availability={availability}
              onToggleAvailability={() => setAvailability(!availability)}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" color="secondary">
                {slotsAvailable.length} / {totalSlots} Slots Marked Available
              </Typography>
              <Box>
                <Button variant="outlined" color="error" sx={{ mr: 2 }}>
                  Apply Leave
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveAvailability}
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Mark Availability'}
                </Button>
              </Box>
            </Box>
            <DatePicker
              value={dayjs(selectedDate)}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Date" />}
              minDate={today}
              maxDate={nextWeekEnd}
            />
            <TimeSlotSelector
              selectedSlots={selectedSlots}
              onSelectSlot={handleSlotSelection}
              availableSlots={unavailableSlots}
              unavailableSlots={slotsAvailable}
            />
            {error && <Typography color="error" mt={2}>{error}</Typography>}
            {success && <Typography color="success" mt={2}>{success}</Typography>}
          </Box>
        )}
         */}
        {/* Tab Panel for Regular Classes */}
        {tabIndex === 0 && (
          <Box>
            <Typography variant="h6">Regular Classes </Typography>
            {/* Add  for Regular Classes here */}
          </Box>
        )}

        {/* Tab Panel for Demo Booked Classes */}
        {tabIndex === 1 && (
          <Box>
            <Typography variant="h6">Demo Booked Classes </Typography>
            {/* Add  for Demo Booked Classes here */}
          </Box>
        )}

        {/* Tab Panel for All Classes */}
        {tabIndex === 2 && (
          <Box>
            <Typography variant="h6">All Classes </Typography>
            {/* Add  for All Classes here */}
          </Box>
        )}
      </Box>
  );
};

export default AvailabilityCard;
