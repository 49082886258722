import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, portrait } from "../Assets/index";
import "../index.css"; // Adjust if needed
import { Button } from "@mui/material";
import baseUrl from "../config";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state

  const navigate = useNavigate();

  const loginUser = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    setError(""); // Reset any previous errors

    try {
      const response = await fetch(baseUrl+'api/teachers/login', { // Use environment variable for URL
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Save the token to local storage
        localStorage.setItem('token', data.token);
        // Optionally, you can also save other data like user ID, etc.
        localStorage.setItem('user', JSON.stringify({
          id: data._id,
          name: data.name,
          email: data.email,
          role: data.role,
          uniqueId: data.uniqueId,
        }));
        navigate("/");
      } else {
        setError(data.message || "Unknown error"); // Set error message
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred. Please try again."); // Set error message
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="app-container">
      <div className="image-section">
        <img className="background-img" src={portrait} alt="Background" />
        <img className="logo" src={oratricslogo} alt="Oratrics Logo" />
      </div>
      <div className="login-section">
        <h1 className="welcome-text" style={{ color: '#050a30' }}>
          Welcome to <span style={{ color: '#8b0000' }}>Oratrics</span>
        </h1>
        <form className="mx-auto mt-4 text-left" onSubmit={loginUser}>
          <div className="mb-8">
            <h2 style={{ color: '#050a30' }} className="text-3xl font-bold mb-2 text-gray-600 text-left">
              Login
            </h2>
            {/* <p className="text-gray-500 text-left" style={{ color: '#050a30' }}>
              Need an account?{" "}
              <span
                onClick={() => navigate("/register")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign Up
              </span>
            </p> */}
          </div>
          <div className="text-left">
            <label className="block mb-1">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              className="w-full px-4 py-2 border rounded-lg mb-4"
              required
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              className="w-full px-4 py-2 border rounded-lg mb-4"
              required
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>} {/* Display error message */}
          <div className="flex justify-between items-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="bg-lime-500 text-white py-2 px-4 rounded-sm"
              disabled={loading} // Disable button while loading
            >
              {loading ? "Logging in..." : "Login"} {/* Change button text while loading */}
            </Button>
            <span className="ml-2 text-sm text-gray-500 cursor-pointer">
              Forget password?
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
