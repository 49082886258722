import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Card, CircularProgress, Divider } from '@mui/material';
import axios from 'axios';

const AttendanceTable = () => {
  const { studentId } = useParams(); // Extract studentId from URL params
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:4000/api/teachers/attendance', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            studentId: studentId // Use studentId from URL params
          }
        });
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [studentId]); // Add studentId to the dependency array

  // Convert date string to a readable format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  );
  
  if (error) return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography color="error">{`Error: ${error}`}</Typography>
    </Box>
  );

  return (
    <Box
      p={3}
      sx={{ height: '100%', backgroundColor: '#f5f5f5' }}
    >
      <Card elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom sx={{color:"#D81B60"}}>
          Attendance History for {data?.student?.name}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography fontWeight="bold">Course</Typography></TableCell>
                <TableCell><Typography fontWeight="bold">Date</Typography></TableCell>
                <TableCell><Typography fontWeight="bold">Time</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.attendanceHistory.map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{record.course}</TableCell>
                  <TableCell>{formatDate(record.date)}</TableCell>
                  <TableCell>{record.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default AttendanceTable;
