import { Box, Avatar, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Edit } from '@mui/icons-material';

const TeacherInfo = ({ teacher }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/profile');
  };
  return (
    <Box display="flex" alignItems="center" mb={3} p={2} sx={{ background: '#f7f7ff', borderRadius: '10px' }}>
      <Avatar src={teacher.avatar} sx={{ width: 70, height: 70, mr: 2 }} />
      <Box>
        <Typography variant="h6">{teacher.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          Teacher | ID {teacher.id}
        </Typography>
      </Box>
      <IconButton sx={{ml:10}} onClick={handleClick}>
      <Edit />
    </IconButton>
    

    </Box>
  );
};

export default TeacherInfo;
