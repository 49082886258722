import { Box, Typography, Paper, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useState } from 'react';

const AchievementCard = ({ achievement }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Paper elevation={2} sx={{ p: 2, mb: 3, borderRadius: '10px', background: achievement.backgroundColor }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          {achievement.status === 'Star' ? (
            <CheckCircleIcon sx={{ color: '#66bb6a', mr: 1 }} />
          ) : (
            <ErrorIcon sx={{ color: '#f44336', mr: 1 }} />
          )}
          <Typography variant="h6">{achievement.title}</Typography>
        </Box>
        <IconButton onClick={handleToggle}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Typography variant="body2" color="textSecondary" mt={1}>
        {achievement.description}
      </Typography>
      <Collapse in={open}>
        <Box mt={2}>
          <Typography variant="body2">{achievement.details}</Typography>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default AchievementCard;
