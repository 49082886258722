// src/utils/timeUtils.js

// Function to generate hourly slots
export const generateHourlySlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      const start = hour < 10 ? `0${hour}:00` : `${hour}:00`;
      const end = hour + 1 < 10 ? `0${hour + 1}:00` : `${hour + 1}:00`;
      slots.push({ label: `${start} - ${end}`, value: `${start}-${end}` });
    }
    return slots;
  };
  