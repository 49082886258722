import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from './reportWebVitals'; // Import default export
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById("root"));

// Conditional rendering for development and production
// if (process.env.NODE_ENV === "development") {
//   root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// } else {
  root.render(<App />);
// }

// Register service worker for offline caching and performance improvements
serviceWorkerRegistration.register();

// Optionally log performance metrics
reportWebVitals(console.log);
