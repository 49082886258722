import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  TextField,
  MenuItem,
  Snackbar,
  CircularProgress,  InputAdornment,  Divider,
  
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import MoodIcon from '@mui/icons-material/Mood';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import baseUrl from '../config';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TabPanel = ({ value, index, children }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    sx={{ p: 3 }}
  >
    {value === index && <Typography>{children}</Typography>}
  </Box>
);

const DemoRequestPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState({
    childName: '',
    parentName: '',
    kidsConfidence: '',
    financialCondition: ''
  });


  console.log(acceptedRequests,"acceptedRequests");
  

  const [newDateTime, setNewDateTime] = useState({ date: '', time: '' });
  const teacher = JSON.parse(localStorage.getItem('user'));
  const [anchorEl, setAnchorEl] = useState(null);

  // Snackbar and Loader state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loader state

  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  useEffect(() => {
    const fetchPendingRequests = async () => {
      const token = getAuthToken();
      setLoading(true); // Show loader
      try {
        const response = await axios.get(`${baseUrl}api/teachers/availableDemobooks`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPendingRequests(response.data);
      } catch (error) {
        setSnackbarMessage('Error fetching pending requests');
        setSnackbarOpen(true);
      }
      setLoading(false); // Hide loader
    };

    const fetchAcceptedRequests = async () => {
      const token = getAuthToken();
      setLoading(true); // Show loader
      try {
        const response = await axios.get(`${baseUrl}api/teachers/meetings`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAcceptedRequests(response.data);
      } catch (error) {
        setSnackbarMessage('Error fetching accepted requests');
        setSnackbarOpen(true);
      }
      setLoading(false); // Hide loader
    };

    fetchPendingRequests();
    fetchAcceptedRequests();
  }, []);

  const handleAccept = async () => {
    const token = getAuthToken();
    setLoading(true); // Show loader
    try {
      await axios.post(`${baseUrl}api/teachers/demoRequestAccept/${selectedRequest._id}`, { teacherId: teacher.id }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPendingRequests(prev => prev.filter(request => request._id !== selectedRequest._id));
      setAcceptedRequests(prev => [...prev, { ...selectedRequest, status: 'accepted' }]);
      setSnackbarMessage('Request accepted successfully');
      setSnackbarOpen(true);
      handleCloseDialog();
    } catch (error) {
      setSnackbarMessage('Error accepting request');
      setSnackbarOpen(true);
      console.log(error);
      
    }
    setLoading(false); // Hide loader
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRequest(null);
  };

  const handleFeedbackDialogClose = () => {
    setFeedbackDialogOpen(false);
  };

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (action) => {
    handleDropdownClose();

    const token = getAuthToken();
    if (action === 'completed') {
      setFeedbackDialogOpen(true);
    } else if (action === 'notConducted') {
      setLoading(true); // Show loader
      try {
        await axios.put(`${baseUrl}api/teachers/demoRequest/${selectedRequest._id}`, { status: 'notConducted' }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSnackbarMessage('Status updated to Not Conducted');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('Error updating status');
        setSnackbarOpen(true);
      }
      setLoading(false); // Hide loader
      handleFeedbackDialogClose();
    } else if (action === 'reschedule') {
      const rescheduleDateTime = prompt("Enter new date and time (YYYY-MM-DD HH:mm):");
      const [date, time] = rescheduleDateTime.split(' ');
      setLoading(true); // Show loader
      try {
        await axios.put(`${baseUrl}api/teachers/demoRequest/${selectedRequest._id}`, { status: 'notConducted', newDateTime: { date, time } }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSnackbarMessage('Demo rescheduled successfully');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('Error rescheduling demo');
        setSnackbarOpen(true);
      }
      setLoading(false); // Hide loader
      handleFeedbackDialogClose();
    }
  };

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prev) => ({ ...prev, [name]: value }));
  };

  const handleFeedbackSubmit = async () => {
    if (!selectedRequest) {
      console.error('No selected request found');
      return;
    }

    const token = getAuthToken();
    const feedbackData = {
      childName: feedback.childName || '',
      parentName: feedback.parentName || '',
      kidsConfidence: feedback.kidsConfidence || '',
      financialCondition: feedback.financialCondition || '',
    };

    setLoading(true); // Show loader
    try {
      await axios.put(`${baseUrl}api/teachers/demoBookings/status/${selectedRequest._id}`, {
        status: 'completed',
        feedback: feedbackData,
      }, {
        headers: { Authorization: `Bearer ${token}` },
     
      });
      setSnackbarMessage('Feedback submitted successfully');
      setSnackbarOpen(true);
      handleFeedbackDialogClose(); // Close dialog after submission
    } catch (error) {
      setSnackbarMessage('Error submitting feedback');
      setSnackbarOpen(true);
    }
    setLoading(false); // Hide loader
  };

  return (
    <Box
    display="flex"
    flexDirection="column"
    padding={3}
    sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
  >      <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
        <Tab label="Pending Requests" />
        <Tab label="Accepted Requests" />
      </Tabs>

      {/* Loader */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ m: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <TabPanel value={tabIndex} index={0}>
        {pendingRequests.length === 0 ? (
          <Typography>No demo requests yet.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingRequests.map(request => (
                  <TableRow key={request._id}>
                    <TableCell>{request.childName}</TableCell>
                    <TableCell>{request.grade}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.time}</TableCell>
                    <TableCell>
                      <Button
                      sx={{bgcolor:"#09135c"}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedRequest(request);
                          setDialogOpen(true);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>

      {acceptedRequests && (
        <TabPanel value={tabIndex} index={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Zoom Link</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acceptedRequests.map(request => (
                  <TableRow key={request._id}>
                    <TableCell>{request.childName}</TableCell>
                    <TableCell>{request.grade}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.time}</TableCell>                    <TableCell>
                      <Button
                      sx={{bgcolor:"#09135c"}}
                        variant="contained"
                        color="primary"
                        href={request.zoomlink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Join Meeting
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={handleDropdownOpen}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleDropdownClose}
                      >
                        <MenuItem
                          onClick={() => {
                            setSelectedRequest(request);
                            handleMenuItemClick('completed');
                          }}
                        >
                          Conducted
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('notConducted')}>
                          Not Conducted
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('reschedule')}>
                          Reschedule
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      )}

      {/* Feedback Dialog */}
      <Dialog
  open={feedbackDialogOpen}
  onClose={handleFeedbackDialogClose}
  fullWidth
  maxWidth="sm"
  sx={{
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
  }}
>
  <DialogTitle sx={{ fontWeight: 700, color: '#D81B60', textAlign: 'center' }}>
    Feedback Form
  </DialogTitle>
  <DialogContent sx={{ bgcolor: '#f9f9f9', p: 4 }}>
    <TextField
      name="childName"
      label="Student Name"
      fullWidth
      margin="normal"
      value={feedback.childName}
      onChange={handleFeedbackChange}
      variant="outlined"
      sx={{ bgcolor: '#fff', borderRadius: '8px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      name="parentName"
      label="Parent Name"
      fullWidth
      margin="normal"
      value={feedback.parentName}
      onChange={handleFeedbackChange}
      variant="outlined"
      sx={{ bgcolor: '#fff', borderRadius: '8px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      name="kidsConfidence"
      label="Kids' Confidence"
      fullWidth
      margin="normal"
      value={feedback.kidsConfidence}
      onChange={handleFeedbackChange}
      variant="outlined"
      sx={{ bgcolor: '#fff', borderRadius: '8px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MoodIcon />
          </InputAdornment>
        ),
      }}
    />
    <TextField
      name="financialCondition"
      label="Financial Condition"
      fullWidth
      margin="normal"
      value={feedback.financialCondition}
      onChange={handleFeedbackChange}
      variant="outlined"
      sx={{ bgcolor: '#fff', borderRadius: '8px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MonetizationOnIcon />
          </InputAdornment>
        ),
      }}
    />
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
    <Button onClick={handleFeedbackDialogClose} color="secondary" variant="outlined" sx={{ marginRight: 1 }}>
      Cancel
    </Button>
    <Button                       sx={{bgcolor:"#09135c"}}
 onClick={handleFeedbackSubmit} variant="contained" color="primary">
      Submit
    </Button>
  </DialogActions>
</Dialog>

      {/* Details Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          bgcolor: '#09135c',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0',
          p: 2,
        }}
      >
        Request Details
        <IconButton onClick={handleCloseDialog} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#f5f5f5', borderRadius: '0 0 8px 8px', p: 3 }}>
        {selectedRequest && (
          <Box>
            <Typography variant="h6" sx={{ mb: 1, color: '#09135c' }}>
              Student Name: <strong>{selectedRequest.childName}</strong>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, color: '#555' }}>
              <strong>Grade:</strong> {selectedRequest.grade}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CalendarTodayIcon sx={{ mr: 1, color: '#09135c' }} />
              <Typography variant="body1" sx={{ color: '#555' }}>
                <strong>Date:</strong> {new Date(selectedRequest.date).toLocaleDateString()}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AccessTimeIcon sx={{ mr: 1, color: '#09135c' }} />
              <Typography variant="body1" sx={{ color: '#555' }}>
                <strong>Time:</strong> {new Date(selectedRequest.date).toLocaleTimeString()}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ color: '#555' }}>
              <strong>Description:</strong> {selectedRequest.description || 'No description available'}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2, bgcolor: '#f5f5f5', borderTop: '1px solid #ddd' }}>
        <Button onClick={handleCloseDialog} variant="outlined" sx={{ color: '#09135c', borderColor: '#09135c' }}>
          Close
        </Button>
        <Button
          onClick={handleAccept}
          color="primary"
          variant="contained"
          sx={{
            bgcolor: '#09135c',
            '&:hover': { bgcolor: '#0a1c77' },
            borderRadius: '20px',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          Accept Request
        </Button>
      </DialogActions>
    </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        autoHideDuration={4000}
      />
    </Box>
  );
};

export default DemoRequestPage;
