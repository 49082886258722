

import oratricslogo from './oratricslogo.png'
import bright from './brightchamps_kid.webp'

import portrait from "./portrait.png"


export {
  portrait,
  oratricslogo,
  bright,

};
