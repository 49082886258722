import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Collapse,
  Card,
  CardContent,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SearchBar from '../Components/SearchBar';
import baseUrl from '../config';
import axios from 'axios';

const AttendanceHistory = ({ records }) => {
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6">Attendance History</Typography>
      {records.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attended Date</TableCell>
              <TableCell>Attended Time</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const attendedDate = new Date(record.attendedAt);
              const dateString = attendedDate.toLocaleDateString();
              const timeString = attendedDate.toLocaleTimeString();

              return (
                <TableRow key={index}>
                  <TableCell>{dateString}</TableCell>
                  <TableCell>{timeString}</TableCell>
                  <TableCell>{record.status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Typography>No attendance records found.</Typography>
      )}
    </Paper>
  );
};

const StudentsList = ({ students, onStudentClick, selectedStudent, attendanceRecords }) => {
  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.length > 0 ? (
              students.map((studentData, index) => {
                const { student, classSchedules, startDate, endDate } = studentData;
                const isSelected = selectedStudent === studentData;

                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>{student.name}</TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>{new Date(startDate).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(endDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                      <Tooltip title={isSelected ? 'Hide Details' : 'Show Details'}>
  <Button
    sx={{
      bgcolor: "#09135c",
      color: 'white',
      '&:hover': {
        bgcolor: '#0a1d8f', // Darker blue on hover
        color: '#e0e0e0'    // Lighter text color on hover
      }
    }}
    variant="outlined"
    onClick={() => onStudentClick(studentData)}
  >
    {isSelected ? 'Hide Details' : 'Show Details'}
  </Button>
</Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={isSelected}>
                        <Card sx={{ borderRadius: 2, boxShadow: 3, mt: 1 }}>
  <CardContent>
    <Typography variant="h6">Student Details</Typography>
    <Divider sx={{ my: 2 }} />
    
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Box sx={{ flex: 1, mr: 2 }}>
        <Typography variant="body1">
          <strong>Name:</strong> {student.name}
        </Typography>
        <Typography variant="body1">
          <strong>Email:</strong> {student.email}
        </Typography>
        <Typography variant="body1">
          <strong>Start Date:</strong> {new Date(startDate).toLocaleDateString()}
        </Typography>
        <Typography variant="body1">
          <strong>End Date:</strong> {new Date(endDate).toLocaleDateString()}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">
          <strong>Class Schedules:</strong>
          {classSchedules.length > 0 ? (
            <ul>
              {classSchedules.map((schedule, index) => (
                <li key={index}>
                  <strong>Start Date:</strong> {new Date(schedule.startDate).toLocaleDateString()}<br />
                  <strong>End Date:</strong> {new Date(schedule.endDate).toLocaleDateString()}<br />
                  <strong>Time:</strong> {schedule.time}<br />
                  <strong>Join URL:</strong>
                  {schedule.joinUrl ? (
                    <a 
                      href={schedule.joinUrl} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{
                        color: '#09135c',
                        textDecoration: 'underline',
                        fontWeight: 'bold'
                      }}
                    >
                      {schedule.joinUrl}
                    </a>
                  ) : 'N/A'}
                </li>
              ))}
            </ul>
          ) : (
            'No classes scheduled'
          )}
        </Typography>
      </Box>
      
      <Box sx={{ flex: 1 }}>
        <AttendanceHistory records={attendanceRecords[student.enrollmentId] || []} />
      </Box>
    </Box>
    
  </CardContent>
</Card>

                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No students found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};


const MyStudent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceRecords, setAttendanceRecords] = useState({});

  const handleStudentClick = async (studentData) => {
    if (selectedStudent === studentData) {
      setSelectedStudent(null);
    } else {
      setSelectedStudent(studentData);
      const enrollmentId = studentData.student.enrollmentId;
      await fetchAttendanceHistory(enrollmentId);
    }
  };

  const fetchAttendanceHistory = async (enrollmentId) => {
    try {
      const response = await axios.get(`${baseUrl}api/teachers/attendance/${enrollmentId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setAttendanceRecords(prev => ({
        ...prev,
        [enrollmentId]: response.data.attendanceRecords || []
      }));
    } catch (error) {
      console.error('Error fetching attendance history:', error);
      alert('Failed to fetch attendance history: ' + (error.response ? error.response.data.message : error.message));
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}api/teachers/students`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch student data');
        }

        const data = await response.json();
        setStudents(data);
      } catch (error) {
        setError('An error occurred while fetching student data.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  return (
    <Box
    display="flex"
    flexDirection="column"
    padding={3}
    sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
  >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: "#09135c" }}>
        My Students
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Total Students Assigned: {students.length}
      </Typography>

      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <SearchBar onSearch={handleSearch} />
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f44336', color: 'white', mb: 4 }}>
          <Typography variant="body1">{error}</Typography>
        </Paper>
      )}
      {!loading && !error && (
        <StudentsList
          students={students.filter((student) =>
            student.student.name.toLowerCase().includes(searchQuery) ||
            student.student.email.toLowerCase().includes(searchQuery)
          )}
          onStudentClick={handleStudentClick}
          selectedStudent={selectedStudent}
          attendanceRecords={attendanceRecords}
        />
      )}
    </Box>
  );
};

export default MyStudent;
