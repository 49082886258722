import React,{useState,useEffect} from 'react';
import { Container, Box, Card } from '@mui/material';
import AchievementCard from '../Components/AchievementCard';
import TeacherInfo from '../Components/TeacherInfo';
import StarTeacherSection from '../Components/StarTeacherSection';
import ProfilePage from '../Components/ProfileView';


// const teacherData = {
//   name: 'Jaya Kapoor',
//   id: '658',
//   avatar: 'path/to/avatar.jpg', // Placeholder for teacher avatar
// };

const achievementData = {
  date: 'August 2024',
  progress: '2/4 Achieved',
};

const achievements = [
  {
    title: 'Student Continuity',
    status: 'Star',
    description: 'Milestone achieved! No students dropped out.',
    details: '',
    backgroundColor: '#e8f5e9',
  },

  {
    title: 'Ratings & Reviews',
    status: 'Poor',
    description: 'Improve your class ratings to become a Star Teacher.',
    details: '',
    backgroundColor: '#ffebee',
  },
];

const SideCard = () => {
  const [teacherData, setTeacherData] = useState({});
  const [achievementData, setAchievementData] = useState({});
  // const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    // Fetch teacher data from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setTeacherData({
        name: user.name,
        id: user.uniqueId,
        avatar: 'path/to/avatar.jpg', // Placeholder, or adjust if needed
      });
    }

    // Fetch achievement data from localStorage
    // const achievementsData = JSON.parse(localStorage.getItem('achievements')) || [];
    // setAchievements(achievementsData);

    // // Placeholder for achievementData (you can adjust as needed)
    // setAchievementData({
    //   date: 'August 2024',
    //   progress: '2/4 Achieved',
    // });
  }, []);

  return (
    <Box
    sx={{
      backgroundColor: '#fff',
      flexDirection: 'column',
      padding: 2,
      height: '100%', // Ensure it takes full height
      overflowY: 'auto',
      position: 'relative',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
      maxWidth: 400,
      // Optional: Add some shadow for better visibility
    }}
  >
    <TeacherInfo teacher={teacherData} />
        <StarTeacherSection achievement={achievementData} />
        {achievements.map((achievement, index) => (
          <AchievementCard key={index} achievement={achievement} />
        ))}
      </Box>
  );
};

export default SideCard;
