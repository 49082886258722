import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  DashboardPage,
  Login,
  
} from './Pages/index';

import Layout from './Layout'; // Adjust import path as needed
import AvailabilityCard from './Pages/AvailabilityCard';
import MyCourse  from './Pages/MyCourse';
import MyGroup from './Pages/MyGroup';
import MyEarnings from './Pages/MyEarning';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; 
import ProtectedRoute from './ProtectedRoute';
import DemoRequestPage from './Pages/DemoRequestPage';
import ProfilePage from './Components/ProfileView';
import MyStudent from './Pages/MyStudent';
import AttendanceTable from './Pages/GetAttendence';
const App = () => {
  return (
    <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
          <Route index element={<DashboardPage />} />
          <Route path="/MyCourse" element={<MyCourse />} />
          <Route path="/student" element={<MyStudent />} />

          <Route path="/Calender" element={<AvailabilityCard />} />
          <Route path="/Earning" element={<MyEarnings />} />
          <Route path="/Group" element={<MyGroup />} />
          <Route path="/checkdemo" element={<DemoRequestPage />} />
          <Route path="/attendance/:studentId" element={<AttendanceTable />} />

          <Route path="/profile" element={<ProfilePage />} />

        </Route>
        </Route>

      </Routes>
      
      </LocalizationProvider>

    </BrowserRouter>
  );
};

export default App;
