import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Button, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import ReportIcon from '@mui/icons-material/Report';
import DownloadIcon from '@mui/icons-material/Download';
import baseUrl from '../config';

const MyEarnings = () => {
    const [earnings, setEarnings] = useState({
        totalEarnings: 0,
        totalDeductions: 0,
        netEarnings: 0,
        paidClassesEarnings: 0,
        demoClassesEarnings: 0,
        incentives: 0,
        deductions: 0,
        pendingEarnings: 0 // New state for pending earnings
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEarnings = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}api/teachers/earnings`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setEarnings(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEarnings();
    }, []);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Box display="flex" flexDirection="column" padding={3} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Typography variant="h5" gutterBottom>My Earnings</Typography>
            <Typography variant="subtitle1" color="textSecondary">August 2024</Typography>

            <Card sx={{ marginTop: 2, padding: 2, display: 'flex', alignItems: 'center',background:'#fae7de' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ background: 'linear-gradient(135deg,  #09135c 50%,  #00008B 50%)', padding: 3, borderRadius: 2, color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4">₹ {earnings.totalEarnings}</Typography>
                        <Typography variant="body1">Total Earnings</Typography>
                        <Typography variant="body2">Deductions ₹ {earnings.totalDeductions}</Typography>
                    </Box>
                </Box>
                <IconButton aria-label="more options" sx={{ marginLeft: 2 }}>
                    <ReportIcon />
                </IconButton>
            </Card>
            <Card 
    sx={{ 
        marginTop: 2, 
        background: 'linear-gradient(135deg,  #0d217a 50%,  #b6854d 50%)', 
        borderRadius: 2,
        padding: 2, // Add some padding to the card
    }}
>
    <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
        <ListItemIcon>
        <ReportIcon sx={{ color: 'white' }} /> {/* Use sx for styling */}
        </ListItemIcon>
        <ListItemText 
            primaryTypographyProps={{ style: { color: 'white' } }} // Set primary text color to white
            secondaryTypographyProps={{ style: { color: 'rgba(255, 255, 255, 0.7)' } }} // Optional: Set secondary text color to a lighter shade of white
            primary="Pending Earnings" 
            secondary="Pending for approval" 
        />
        <Typography variant="h4" sx={{ color: 'white' }}>₹ {earnings.pendingEarnings}</Typography>
    </ListItem>
</Card>


            <List sx={{ marginTop: 2 }}>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <AccountBalanceWalletIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Paid Classes" secondary="Classes" />
                    <Typography variant="body3">₹ {earnings.paidClassesEarnings}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <SchoolIcon color="info" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Demo Classes" secondary="Classes" />
                    <Typography variant="body1">₹ {earnings.demoClassesEarnings}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <EmojiEventsIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Earnings from Incentives" secondary="Incentives" />
                    <Typography variant="body1">₹ {earnings.incentives}</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <StarIcon color="warning" />
                    </ListItemIcon>
                    <ListItemText primary="Star Teacher Reward" secondary="Get 10% extra payout by becoming a Star Teacher in August." />
                    <Typography variant="body1">₹ 0</Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemIcon>
                        <ReportIcon color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Deductions" secondary="Deductions" />
                    <Typography variant="body1">₹ {earnings.deductions}</Typography>
                </ListItem>
                <Divider />
                {/* New Pending Earnings Section */}
              
            </List>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
                <Button variant="outlined" startIcon={<DownloadIcon />}>Download Payslip</Button>
                <Button variant="contained" color="primary">Detailed Breakdown</Button>
            </Box>

            <Box sx={{ marginTop: 3, padding: 2, backgroundColor: '#d5d8f1', borderRadius: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" color="primary.main">32% perfect classes this month</Typography>
                <Button size="small" color="info">What is a perfect class?</Button>
            </Box>
        </Box>
    );
};

export default MyEarnings;
