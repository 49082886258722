import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  Link,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchBar from '../Components/SearchBar';
import baseUrl from '../config';

const fetchCourseData = async () => {
  try {
    const response = await fetch(`${baseUrl}api/teachers/assigned-courses`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error;
  }
};

const MyCourse = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [expandedCourse, setExpandedCourse] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const courseData = await fetchCourseData();
        setCourses(courseData.courses);
        setCourseCount(courseData.courseCount);
      } catch (error) {
        setError('An error occurred while fetching course data.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const handleCourseClick = (course) => {
    setExpandedCourse(expandedCourse === course ? null : course);
  };

  const filteredCourses = (courses) =>
    courses.filter((course) => course.title.toLowerCase().includes(searchQuery));

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={3}
      sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
    >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#09135c' }}>
        My Courses
      </Typography>
      <Typography variant="h6" gutterBottom>
        Total Courses Assigned: {courseCount}
      </Typography>
      <SearchBar onSearch={handleSearch} />

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {!loading && !error && (
        <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2, boxShadow: 3, overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Duration</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Course Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCourses(courses)?.map((course) => (
                <React.Fragment key={course._id}>
                  <TableRow hover>
                    <TableCell>{course?.title}</TableCell>
                    <TableCell>{course?.description}</TableCell>
                    <TableCell>{course?.duration} mins</TableCell>
                    <TableCell>{course?.courseType}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ bgcolor: '#09135c', color: 'white', '&:hover': { bgcolor: '#0a1a8f' } }}
                        variant="contained"
                        onClick={() => handleCourseClick(course)}
                      >
                        {expandedCourse === course ? 'Hide Details' : 'Show Details'}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {expandedCourse === course && (
                <TableRow>
                <TableCell colSpan={5} sx={{ paddingBottom: 0 }}>
                  {expandedCourse === course && (
                    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', mt: 1 }}>
                      <Typography variant="h6" sx={{ color: '#09135c' }}>Course Details</Typography>
                      <Typography variant="body1">
                        <strong>Title:</strong> {course?.title}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Description:</strong> {course?.description}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Duration:</strong> {course?.duration} minutes
                      </Typography>
                      <Typography variant="body1">
                        <strong>Course Type:</strong> {course?.courseType}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Booked Classes:</strong> {course?.bookedClasses}
                      </Typography>
              
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Class Schedule</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {course?.classSchedule?.map(schedule => (
                            <li key={schedule?._id}>
                              {new Date(schedule?.date).toLocaleDateString()} at {schedule?.time}
                            </li>
                          ))}
                        </ul>
                      </Box>
              
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Chapters</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {course?.chapters?.map(chapter => (
                            <li key={chapter?._id}>
                              <strong>{chapter?.title}:</strong>
                              <Link
                                href={chapter?.description}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ ml: 1, color: '#1976d2', '&:hover': { textDecoration: 'underline' } }}
                              >
                                {chapter?.description}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
              
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MyCourse;
