import Login from "./Login";
import Register from "./Register";
import DashboardPage from "./DashboardPage";


export {
  Login,
  Register,
  DashboardPage,

};
