import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { generateHourlySlots } from '../utils/timeUtils';

const Pillar = styled(Box)(({ selected, disabled }) => ({
  width: '150px',
  height: '250px',
  backgroundColor: selected ? '#f0860cfd' : (disabled ? 'lightgray' : 'lightblue'),
  borderRadius: '8px',
  margin: '5px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  transition: 'background-color 0.3s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: '14px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: disabled ? 'lightgray' : '#f0860cfd',
  }
}));

const TimeSlotSelector = ({ selectedSlots, onSelectSlot, availableSlots, unavailableSlots }) => {
  const handlePillarClick = (slotValue) => {
    if (!unavailableSlots.includes(slotValue)) {
      onSelectSlot(slotValue);
    }
  };

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
      {generateHourlySlots().map((slot) => (
        <Tooltip key={slot.value} title={slot.label} arrow>
          <Pillar
            selected={selectedSlots.includes(slot.value)}
            disabled={unavailableSlots.includes(slot.value)} // Check if the slot is disabled
            onClick={() => handlePillarClick(slot.value)}
          >
            {slot.label}
          </Pillar>
        </Tooltip>
      ))}
    </Box>
  );
};

// Function to generate hourly slots


export default TimeSlotSelector;
