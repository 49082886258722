import { Box, TextField } from '@mui/material';

const SearchBar = ({ onSearch }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search students by Name"
        onChange={(e) => onSearch(e.target.value)}
      />
    </Box>
  );
};

export default SearchBar;
