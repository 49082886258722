import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ManubarPage from './Pages/ManubarPage';

const Layout = () => {
  // State to hold if the view is mobile or not
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update state based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <ManubarPage />
      <main
        className={`flex-1 overflow-auto ${isMobile ? 'pt-20' : ''}`} // Apply padding only in mobile view
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
