// serviceWorkerRegistration.js

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.[0-9]{1,3}){3}$/
    )
  );
  
  /**
   * Registers a service worker.
   */
  export function register() {
    if ('serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);
      } else {
        // Is not localhost. Just register the service worker.
        registerValidSW(swUrl);
      }
    }
  }
  
  /**
   * Register a valid service worker.
   * @param {string} swUrl - The URL of the service worker.
   */
  function registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        console.log('Service Worker registered:', registration);
      })
      .catch((error) => {
        console.error('Error registering service worker:', error);
      });
  }
  
  /**
   * Check if there's a valid service worker.
   * @param {string} swUrl - The URL of the service worker.
   */
  function checkValidServiceWorker(swUrl) {
    // Check if the service worker is still running.
    fetch(swUrl)
      .then((response) => {
        if (response.status === 404 || response.type === 'application/json') {
          // No service worker found. Unregister.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed with registration.
          registerValidSW(swUrl);
        }
      })
      .catch(() => {
        console.error('No internet connection. Service worker not registered.');
      });
  }
  
  /**
   * Unregister the service worker.
   */
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error('Error unregistering service worker:', error);
        });
    }
  }
  