import { Grid } from '@mui/material';
import GroupCard from './GroupCard';

const GroupsList = ({ groups }) => {
  return (
    <Grid container spacing={2}>
      {groups.map((group) => (
        <Grid item key={group.id}>
          <GroupCard group={group} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GroupsList;
