import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  faTachometerAlt, faCalendar, faClipboardList, faDollarSign, faTasks, faStar
} from '@fortawesome/free-solid-svg-icons';
import {
  oratricslogo,

} from "../Assets/index";
import { useNavigate } from "react-router-dom";

const ManubarPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const menuItems = [
    { name: "My class", icon: faTachometerAlt, link: "/" },
    { name: "Demo Request", icon: faTasks, link: "/checkdemo" },

    { name: "Calender", icon: faCalendar, link: "/Calender" },

    { name: "My Student", icon: faClipboardList, link: "/Student" },
    { name: "My Course", icon: faClipboardList, link: "/MyCourse" },

    { name: "Earning", icon: faDollarSign, link: "/Earning" },

    // { name: "My Rewards", icon: faStar, link: "/reward" },

    // { name: "My class", icon: Home, link: "/" },
    // { name: "Calender", icon: CalendarMonth, link: "/Calender" },
    // { name: "My Student", icon: user, link: "/Student" },
    // // { name: "Student Group", icon: customer, link: "/Group" },
    // { name: "My Earning", icon:  , link: "/Earning" },
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleLogout = async () => {
    try {
      // Optionally notify the server about the logout

      // Remove token from local storage or cookies
      localStorage.removeItem('authToken'); // Adjust key if needed

      // Redirect to login or home page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <>
      {/* Mobile Header Navbar */}
      <header className="lg:hidden fixed top-0 left-0 w-full bg-white shadow-md z-50 flex items-center justify-between p-4" style={{ height: '80px' }}>
        <button
          className="p-5 text-gray-700"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FontAwesomeIcon className="h-8 " icon={showMenu ? faTimes : faBars} />
        </button>
        <img src={oratricslogo} alt="Company Logo" className="h-14 w-auto" />
      </header>

      {/* Mobile Sidebar Menu */}
      <div
        className={`fixed top-5 left-0 w-64 h-full bg-gray-200 text-gray-500 z-40 transition-transform duration-300 ease-in-out lg:hidden ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}
        style={{ height: '100vh', overflowY: 'auto', backgroundColor: '#fff', padding: 30 }}
      >
        <div className="flex flex-col h-full w-full bg-white">
          {/* Menu Items */}
          <div className="flex-1 flex flex-col pt-10">
            {menuItems.map((item, index) => (
              <a
                key={index}
                href={item.link}
                style={{color:  selectedItem === item ? "text-white #bac2ff" : "#09135c"
                }  }
                className={`px-4 py-3 flex items-center cursor-pointer w-full ${
                  selectedItem === item ? "text-white #050a30" : "#050a30"
                } ${
                  selectedItem === item ? "rounded-lg mb-2" : "hover:bg-blue-100 hover:rounded-lg mb-2"
                }`}
                onClick={() => handleItemClick(item)}
              >
                <FontAwesomeIcon icon={item.icon} className="h-10 w-10 mr-7" />
                <span className="text-base font-medium">{item.name}</span>
              </a>
            ))}
          </div>

          {/* Footer Section */}
          <div className="px-4 py-3 mt-auto">
            <div className="flex items-center">
              {/* <img src={customer} alt="BrightBuddy" className="h-10 w-10 mr-3" /> */}
              <span className="text-pink-700 font-semibold">Oratrics</span>
            </div>
            <div className="mt-4 text-gray-600 text-sm">
              <p>Need Help</p>
              <a href="#" className="text-pink-600">
                Ask Oratrics
              </a>
              <button onClick={handleLogout}>
      Logout
    </button>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Sidebar Menu */}
      <div className="hidden lg:block lg:sticky lg:top-0 bg-gray-200 text-gray-500"
        style={{
          height: '100vh',
          overflowY: 'auto',
          backgroundColor: '#fff',
          padding: 30,
        }}
      >
        <div className="flex flex-col h-full w-full lg:w-64 bg-white">
          {/* Logo Section */}
          <div className="flex items-center justify-center h-24">
            <img src={oratricslogo} alt="Company Logo" className="h-22 w-auto" />
          </div>

          {/* Menu Items */}
          <div className="flex-1 flex flex-col mt-5">
            {menuItems.map((item, index) => (
              <a
                key={index}
                href={item.link}
                style={{color:  selectedItem === item ? "text-white #bac2ff" : "#09135c"
                }  }
                  className={`px-4 py-3 flex items-center cursor-pointer w-full ${
                    selectedItem === item ? "text-white #050a30" : "#050a30"
                  } ${
                    selectedItem === item ? "rounded-lg mb-2" : "hover:bg-blue-100 hover:rounded-lg mb-2"
                  }`}
                onClick={() => handleItemClick(item)}
              >
                <FontAwesomeIcon icon={item.icon} className="h-10 w-10 mr-7" />
                <span className="text-base font-medium">{item.name}</span>
              </a>
            ))}
          </div>

          {/* Footer Section */}
          <div className="px-4 py-3 mt-auto">
            <div className="flex items-center">
              {/* <img src={customer} alt="BrightBuddy" className="h-10 w-10 mr-3" /> */}
              <span className="text-pink-700 font-semibold">Oratrics</span>
            </div>
            <div className="mt-4 text-gray-600 text-sm">
              <p>Need Help</p>
              <a href="#" className="text-pink-600">
                Ask Oratrics
              </a>
              <button onClick={handleLogout}>
      Logout
    </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManubarPage;
