import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, bright, portrait } from "../Assets/index";
import "../index.css"; // Ensure you have the CSS imported

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const registerUser = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("http://localhost:5000/api/register", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          role,
          password,
        }),
      });
      const data = await response.json();
      console.log(data); // Log the response data to the console
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="app-container">
      <div className="image-section">
        <img className="background-img" src={portrait} alt="Background" />
        <img className="logo" src={oratricslogo} alt="Oratrics Logo" />
      </div>
      <div className="login-section">
        <h1 className="welcome-text" style={{ color: '#050a30' }}>
          Welcome to <span style={{ color: '#8b0000' }}>Oratrics</span>
        </h1>
        <form onSubmit={registerUser} className="mx-auto mt-4 text-left">
          <div className="mb-8">
            <h2 className="text-3xl font-bold mb-2" style={{ color: '#050a30' }}>
              Register
            </h2>
            <p className="text-gray-500 text-left" style={{ color: '#050a30' }}>
              Already have an account?{" "}
              <span
                onClick={() => navigate("/")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign In
              </span>
            </p>
          </div>
          <div className="text-left">
            <label className="block mb-1">Full Name</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              type="text"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Role</label>
            <input
              value={role}
              onChange={(e) => setRole(e.target.value)}
              placeholder="Role"
              type="text"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-lime-500 text-white py-2 px-4 rounded-sm"
            >
              Register
            </button>
            <span className="text-sm text-gray-500 cursor-pointer">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign In
              </span>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
